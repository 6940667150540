const prices = [
    // 2024年度
    // 72 東
    { cid: 10, club72: true, prices: [
            { start: '2024-11-05', end: '2024-12-08', price: 6400, weekend: 9600 },
            { start: '2024-04-20', end: '2024-11-04', price: 9100, weekend: 15200 },
            { start: '2024-07-01', end: '2024-10-20', price: 12400, weekend: 19300 },
            { start: '2024-07-13', end: '2024-08-31', price: 14300, weekend: 20500 },
        ]
    },
    { cid: 10, prices: [
            { start: '2024-11-05', end: '2024-12-08', price: 10100, weekend: 17300 },
            { start: '2024-04-20', end: '2024-11-04', price: 15700, weekend: 23400 },
            { start: '2024-07-01', end: '2024-10-20', price: 19900, weekend: 27500 },
            { start: '2024-07-13', end: '2024-08-31', price: 28000, weekend: 28500 },
        ]
    },
    // 72 西
    { cid: 3, club72: true, prices: [
            { start: '2024-04-01', end: '2024-11-24', price: 5100, weekend: 8800 },
            { start: '2024-04-20', end: '2024-11-04', price: 7100, weekend: 11200 },
            { start: '2024-07-01', end: '2024-10-20', price: 10400, weekend: 15300 },
            { start: '2024-07-13', end: '2024-08-31', price: 10800, weekend: 17000 },
        ]
    },
    { cid: 3, prices: [
            { start: '2024-04-01', end: '2024-11-24', price: 7800, weekend: 14800 },
            { start: '2024-04-20', end: '2024-11-04', price: 10800, weekend: 18800 },
            { start: '2024-07-01', end: '2024-10-20', price: 14800, weekend: 21000 },
            { start: '2024-07-13', end: '2024-08-31', price: 21000, weekend: 21500 },
        ]
    },
    // 72 南
    { cid: 4, club72: true, prices: [
            { start: '2024-04-06', end: '2024-12-01', price: 5100, weekend: 8800 },
            { start: '2024-04-20', end: '2024-11-04', price: 7100, weekend: 11200 },
            { start: '2024-07-01', end: '2024-10-20', price: 10400, weekend: 15300 },
            { start: '2024-07-13', end: '2024-08-31', price: 10800, weekend: 17000 },
        ]
    },
    { cid: 4, prices: [
            { start: '2024-04-06', end: '2024-12-01', price: 7800, weekend: 14800 },
            { start: '2024-04-20', end: '2024-11-04', price: 10800, weekend: 18800 },
            { start: '2024-07-01', end: '2024-10-20', price: 14800, weekend: 21000 },
            { start: '2024-07-13', end: '2024-08-31', price: 21000, weekend: 21500 },
        ]
    },
    // 72 北
    { cid: 5, club72: true, prices: [
            { start: '2024-04-27', end: '2024-11-10', price: 15200, weekend: 20300 },
            { start: '2024-07-01', end: '2024-10-20', price: 20300, weekend: 24700 },
            { start: '2024-07-13', end: '2024-08-31', price: 24800, weekend: 26700 },
        ]
    },
    { cid: 5, prices: [
            { start: '2024-04-27', end: '2024-11-10', price: 25300, weekend: 30500 },
            { start: '2024-07-01', end: '2024-10-20', price: 29500, weekend: 36600 },
            { start: '2024-07-13', end: '2024-08-31', price: 38000, weekend: 38000 },
        ]
    },
    // 浅間
    { cid: 7, club72: true, prices: [
            { start: '2024-04-06', end: '2024-11-24', price: 5100, weekend: 8800 },
            { start: '2024-04-20', end: '2024-11-04', price: 7100, weekend: 11200 },
            { start: '2024-07-01', end: '2024-10-14', price: 9500, weekend: 14000 },
            { start: '2024-07-13', end: '2024-09-08', price: 10800, weekend: 16500 },
            { start: '2024-08-10', end: '2024-08-18', price: 14900, weekend: 16600 },
        ]
    },
    { cid: 7, prices: [
            { start: '2024-04-06', end: '2024-11-24', price: 9800, weekend: 15400 },
            { start: '2024-04-20', end: '2024-11-04', price: 14300, weekend: 19700 },
            { start: '2024-07-01', end: '2024-10-14', price: 15400, weekend: 20900 },
            { start: '2024-07-13', end: '2024-09-08', price: 19400, weekend: 23000 },
            { start: '2024-08-10', end: '2024-08-18', price: 23600, weekend: 23600 },
        ]
    },
    // 馬越
    { cid: 8, club72: true, mg_half: true, prices: [
            { start: '2024-04-06', end: '2024-11-24', price: 2500, weekend: 4200 },
            { start: '2024-05-07', end: '2024-10-31', price: 4100, weekend: 5600 },
            { start: '2024-07-13', end: '2024-08-31', price: 4600, weekend: 6800 },
            { start: '2024-04-27', end: '2024-05-06', price: 5800, weekend: 8400 },
            { start: '2024-08-03', end: '2024-08-20', price: 5800, weekend: 8400 },
        ]
    },
    { cid: 8, mg_half: true, prices: [
            { start: '2024-04-06', end: '2024-11-24', price: 3900, weekend: 6300 },
            { start: '2024-05-07', end: '2024-10-31', price: 5400, weekend: 7500 },
            { start: '2024-07-13', end: '2024-08-31', price: 6900, weekend: 8800 },
            { start: '2024-04-27', end: '2024-05-06', price: 8300, weekend: 10400 },
            { start: '2024-08-03', end: '2024-08-20', price: 8300, weekend: 10400 },
        ]
    },

    // 小諸高原
    { cid: 110, prices: [
            { start: '2024-04-13', end: '2024-04-26', price: 7400, saturday: 13500, holiday: 12500 },
            { start: '2024-04-27', end: '2024-05-02', price: 13500, saturday: 13500, holiday: 13500 },
            { start: '2024-05-03', end: '2024-05-05', price: 16300, saturday: 16300, holiday: 16300 },
            { start: '2024-05-06', end: '2024-05-12', price: 7400, saturday: 13500, holiday: 12500 },
            { start: '2024-05-13', end: '2024-05-30', price: 8400, saturday: 13500, holiday: 12500 },
            { start: '2024-07-01', end: '2024-07-12', price: 8400, saturday: 16300, holiday: 15300 },
            { start: '2024-07-29', end: '2024-09-01', price: 11800, saturday: 16300, holiday: 15300 },
            { start: '2024-08-05', end: '2024-08-15', price: 11800, saturday: 19300, holiday: 19300 },
            { start: '2024-09-02', end: '2024-11-04', price: 8400, saturday: 14500, holiday: 13500 },
            { start: '2024-11-05', end: '2024-11-07', price: 7400, saturday: 14500, holiday: 13500 },
            { start: '2024-12-09', end: '2024-12-15', price: 5000, saturday: 7000, holiday: 7000 },
        ]
    },
    // 小諸高原 食事付き
    { cid: 111, prices: [
            { start: '2024-03-30', end: '2024-04-12', price: 6900, saturday: 12500, holiday: 11500 },
            { start: '2024-11-18', end: '2024-12-08', price: 7400, saturday: 12500, holiday: 11500 },
        ]
    },
    // 三井の森 食事付き
    { cid: 112, prices: [
            { start: '2024-04-03', end: '2024-04-19', price: 6000, weekend: 9000 },
            { start: '2024-04-20', end: '2024-04-26', price: 6500, weekend: 12000 },
            { start: '2024-04-27', end: '2024-04-28', price: 16000 },
            { start: '2024-04-29', price: 15000 },
            { start: '2024-04-30', end: '2024-05-06', price: 15000 },
            { start: '2024-05-03', end: '2024-05-05', price: 16000 },
            { start: '2024-05-07', end: '2024-06-30', price: 7300, weekend: 12800 },
            { start: '2024-07-01', end: '2024-07-12', price: 8000, weekend: 13000 },
            { start: '2024-07-13', end: '2024-07-31', price: 9800, weekend: 16800 },
            { start: '2024-07-15', price: 15800 },
            { start: '2024-08-01', end: '2024-08-31', price: 12800, weekend: 17000 },
            { start: '2024-09-01', end: '2024-09-30', price: 10000, weekend: 15000 },
            { start: '2024-09-16', price: 14000 },
            { start: '2024-09-23', price: 14000 },
            { start: '2024-10-01', end: '2024-11-01', price: 8000, weekend: 14000 },
            { start: '2024-10-14', price: 13000 },
            { start: '2024-11-02', end: '2024-11-03', price: 12000 },
            { start: '2024-11-04', end: '2024-11-29', price: 6000, weekend: 11000 },
            { start: '2024-11-23', end: '2024-11-24', price: 10000 },
            { start: '2024-11-30', price: 9000 },
        ]
    },
    // 中軽
    { cid: 118, prices: [
            { start: '2024-05-05', end: '2024-05-06', price: 26800 },
            { start: '2024-05-07', end: '2024-06-30', price: 10800, weekend: 16800 },
        ]
    },
    // 中軽(食)
    { cid: 119, prices: [
            { start: '2024-04-01', end: '2024-04-06', price: 8800, weekend: 12800 },
            { start: '2024-04-08', end: '2024-04-19', price: 9800, weekend: 13800 },
            { start: '2024-04-20', end: '2024-04-26', price: 10800, weekend: 14800 },
        ]
    },
    // ヴィレッジ(食)
    { cid: 120, prices: [
            { start: '2024-01-01', end: '2024-08-31', price: 5400, weekend: 9400 },
            { start: '2024-09-01', end: '2024-12-31', price: 6400, weekend: 11600 },
        ]
    },
    // ヴィレッジ(食)
    { cid: 120, vpass: true, prices: [
            { start: '2024-01-01', end: '2024-12-31', price: 4300, weekend: 7300 },
        ]
    },


    // // 2023年度
    // // 72 東
    // { cid: 10, club72: true, prices: [
    //         { start: '2023-04-01', end: '2023-04-21', price: 6400, weekend: 9600 },
    //         { start: '2023-11-06', end: '2023-11-26', price: 6400, weekend: 9600 },
    //         { start: '2023-04-22', end: '2023-06-30', price: 9100, weekend: 15200 },
    //         { start: '2023-10-23', end: '2023-11-05', price: 9100, weekend: 15200 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 12400, weekend: 19300 },
    //         { start: '2023-09-01', end: '2023-10-22', price: 12400, weekend: 19300 },
    //         { start: '2023-07-15', end: '2023-08-31', price: 14300, weekend: 20500 },
    //     ]
    // },
    // { cid: 10, prices: [
    //         { start: '2023-04-01', end: '2023-04-21', price: 10100, weekend: 17300 },
    //         { start: '2023-11-06', end: '2023-11-26', price: 10100, weekend: 17300 },
    //         { start: '2023-04-22', end: '2023-06-30', price: 15700, weekend: 23400 },
    //         { start: '2023-10-23', end: '2023-11-05', price: 15700, weekend: 23400 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 19900, weekend: 27500 },
    //         { start: '2023-09-01', end: '2023-10-22', price: 19900, weekend: 27500 },
    //         { start: '2023-07-15', end: '2023-08-31', price: 28000, weekend: 28500 },
    //     ]
    // },
    // // 72 西
    // { cid: 3, club72: true, prices: [
    //         { start: '2023-04-15', end: '2023-04-21', price: 4900, weekend: 8600 },
    //         { start: '2023-11-06', end: '2023-12-03', price: 4900, weekend: 8600 },
    //         { start: '2023-04-22', end: '2023-06-30', price: 6700, weekend: 9600 },
    //         { start: '2023-10-23', end: '2023-11-05', price: 6700, weekend: 9600 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 10000, weekend: 14300 },
    //         { start: '2023-09-01', end: '2023-10-22', price: 10000, weekend: 14300 },
    //         { start: '2023-07-15', end: '2023-08-31', price: 10500, weekend: 16300 },
    //     ]
    // },
    // { cid: 3, prices: [
    //         { start: '2023-04-15', end: '2023-04-21', price: 6600, weekend: 12500 },
    //         { start: '2023-11-06', end: '2023-12-03', price: 6600, weekend: 12500 },
    //         { start: '2023-04-22', end: '2023-06-30', price: 9900, weekend: 16800 },
    //         { start: '2023-10-23', end: '2023-11-05', price: 9900, weekend: 16800 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 13800, weekend: 20100 },
    //         { start: '2023-09-01', end: '2023-10-22', price: 13800, weekend: 20100 },
    //         { start: '2023-07-15', end: '2023-08-31', price: 21000, weekend: 21500 },
    //     ]
    // },
    // // 72 南
    // { cid: 4, club72: true, prices: [
    //         { start: '2023-04-08', end: '2023-04-21', price: 4900, weekend: 8600 },
    //         { start: '2023-11-06', end: '2023-11-26', price: 4900, weekend: 8600 },
    //         { start: '2023-04-22', end: '2023-06-30', price: 6700, weekend: 9600 },
    //         { start: '2023-10-23', end: '2023-11-05', price: 6700, weekend: 9600 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 10000, weekend: 14300 },
    //         { start: '2023-09-01', end: '2023-10-22', price: 10000, weekend: 14300 },
    //         { start: '2023-07-15', end: '2023-08-31', price: 10500, weekend: 16300 },
    //     ]
    // },
    // { cid: 4, prices: [
    //         { start: '2023-04-15', end: '2023-04-21', price: 6600, weekend: 12500 },
    //         { start: '2023-11-06', end: '2023-12-03', price: 6600, weekend: 12500 },
    //         { start: '2023-04-22', end: '2023-06-30', price: 9900, weekend: 16800 },
    //         { start: '2023-10-23', end: '2023-11-05', price: 9900, weekend: 16800 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 13800, weekend: 20100 },
    //         { start: '2023-09-01', end: '2023-10-22', price: 13800, weekend: 20100 },
    //         { start: '2023-07-15', end: '2023-08-31', price: 21000, weekend: 21500 },
    //     ]
    // },
    // // 72 北
    // { cid: 5, club72: true, prices: [
    //         { start: '2023-04-29', end: '2023-06-30', price: 14100, weekend: 20300 },
    //         { start: '2023-10-23', end: '2023-11-12', price: 14100, weekend: 20300 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 19500, weekend: 24700 },
    //         { start: '2023-09-01', end: '2023-10-22', price: 19500, weekend: 24700 },
    //         { start: '2023-07-15', end: '2023-08-31', price: 23800, weekend: 26700 },
    //     ]
    // },
    // { cid: 5, prices: [
    //         { start: '2023-04-29', end: '2023-06-30', price: 22300, weekend: 30500 },
    //         { start: '2023-10-23', end: '2023-11-12', price: 22300, weekend: 30500 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 29500, weekend: 36600 },
    //         { start: '2023-09-01', end: '2023-10-22', price: 29500, weekend: 36600 },
    //         { start: '2023-07-15', end: '2023-08-31', price: 37200, weekend: 37200 },
    //     ]
    // },
    // // 72 北 セルフ club72限定
    // { cid: 11, club72: true, prices: [
    //         { start: '2023-04-29', end: '2023-05-07', price: 12100, weekend: 18300 },
    //         { start: '2023-05-13', end: '2023-05-14', price: 12100, weekend: 18300 },
    //         { start: '2023-05-20', end: '2023-05-21', price: 12100, weekend: 18300 },
    //         { start: '2023-05-27', end: '2023-05-28', price: 12100, weekend: 18300 },
    //         { start: '2023-06-01', end: '2023-06-30', price: 12100, weekend: 18300 },
    //         { start: '2023-10-23', end: '2023-11-12', price: 12100, weekend: 18300 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 17500, weekend: 22700 },
    //         { start: '2023-09-01', end: '2023-10-22', price: 17500, weekend: 22700 },
    //         { start: '2023-07-15', end: '2023-08-31', price: 21800, weekend: 24700 },
    //     ]
    // },
    // // 晴山
    // { cid: 6, club72: true, prices: [
    //         { start: '2023-04-08', end: '2023-04-28', price: 4865, weekend: 7865 },
    //         { start: '2023-05-08', end: '2023-06-30', price: 4865, weekend: 7865 },
    //         { start: '2023-10-10', end: '2023-11-26', price: 4865, weekend: 7865 },
    //         { start: '2023-04-29', end: '2023-05-07', price: 5865, weekend: 9465 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 5865, weekend: 9465 },
    //         { start: '2023-09-01', end: '2023-10-09', price: 5865, weekend: 9465 },
    //         { start: '2023-07-15', end: '2023-08-04', price: 7365, weekend: 10165 },
    //         { start: '2023-08-21', end: '2023-08-31', price: 7365, weekend: 10165 },
    //         { start: '2023-08-05', end: '2023-08-20', price: 10165, weekend: 10165 },
    //     ]
    // },
    // { cid: 6, prices: [
    //         { start: '2023-04-08', end: '2023-04-28', price: 7465, weekend: 11665 },
    //         { start: '2023-05-08', end: '2023-06-30', price: 7465, weekend: 11665 },
    //         { start: '2023-10-10', end: '2023-11-26', price: 7465, weekend: 11665 },
    //         { start: '2023-04-29', end: '2023-05-07', price: 8465, weekend: 13165 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 8465, weekend: 13165 },
    //         { start: '2023-09-01', end: '2023-10-09', price: 8465, weekend: 13165 },
    //         { start: '2023-07-15', end: '2023-08-04', price: 10965, weekend: 15765 },
    //         { start: '2023-08-21', end: '2023-08-31', price: 10965, weekend: 15765 },
    //         { start: '2023-08-05', end: '2023-08-20', price: 15765, weekend: 15765 },
    //     ]
    // },
    // // 浅間
    // { cid: 7, club72: true, prices: [
    //         { start: '2023-04-08', end: '2023-04-21', price: 4400, weekend: 8400 },
    //         { start: '2023-11-06', end: '2023-11-26', price: 4400, weekend: 8400 },
    //         { start: '2023-04-22', end: '2023-06-30', price: 6500, weekend: 9600 },
    //         { start: '2023-10-10', end: '2023-11-05', price: 6500, weekend: 9600 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 8200, weekend: 12500 },
    //         { start: '2023-09-11', end: '2023-10-09', price: 8200, weekend: 12500 },
    //         { start: '2023-07-15', end: '2023-08-04', price: 10200, weekend: 15500 },
    //         { start: '2023-08-17', end: '2023-09-10', price: 10200, weekend: 15500 },
    //         { start: '2023-08-05', end: '2023-08-16', price: 13800, weekend: 15500 },
    //
    //     ]
    // },
    // { cid: 7, prices: [
    //         { start: '2023-04-08', end: '2023-04-21', price: 8300, weekend: 13900 },
    //         { start: '2023-11-06', end: '2023-11-26', price: 8300, weekend: 13900 },
    //         { start: '2023-04-22', end: '2023-06-30', price: 12800, weekend: 18200 },
    //         { start: '2023-10-10', end: '2023-11-05', price: 12800, weekend: 18200 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 13800, weekend: 19300 },
    //         { start: '2023-09-11', end: '2023-10-09', price: 13800, weekend: 19300 },
    //         { start: '2023-07-15', end: '2023-08-04', price: 17900, weekend: 21500 },
    //         { start: '2023-08-17', end: '2023-09-10', price: 17900, weekend: 21500 },
    //         { start: '2023-08-05', end: '2023-08-16', price: 22000, weekend: 22000 },
    //
    //     ]
    // },
    // // 馬越
    // { cid: 8, club72: true, mg_half: true, prices: [
    //         { start: '2023-04-08', end: '2023-04-28', price: 2100, weekend: 3850 },
    //         { start: '2023-11-13', end: '2023-11-26', price: 2100, weekend: 3850 },
    //         { start: '2023-05-08', end: '2023-06-23', price: 3750, weekend: 5200 },
    //         { start: '2023-10-10', end: '2023-11-12', price: 3750, weekend: 5200 },
    //         { start: '2023-06-24', end: '2023-07-14', price: 4200, weekend: 6400 },
    //         { start: '2023-09-04', end: '2023-10-09', price: 4200, weekend: 6400 },
    //         { start: '2023-04-29', end: '2023-05-07', price: 5450, weekend: 7900 },
    //         { start: '2023-07-15', end: '2023-09-04', price: 5450, weekend: 7900 },
    //     ]
    // },
    // { cid: 8, mg_half: true, prices: [
    //         { start: '2023-04-08', end: '2023-04-28', price: 3500, weekend: 5900 },
    //         { start: '2023-11-13', end: '2023-11-26', price: 3500, weekend: 5900 },
    //         { start: '2023-05-08', end: '2023-06-23', price: 4950, weekend: 7100 },
    //         { start: '2023-10-10', end: '2023-11-12', price: 4950, weekend: 7100 },
    //         { start: '2023-06-24', end: '2023-07-14', price: 6600, weekend: 8400 },
    //         { start: '2023-09-04', end: '2023-10-09', price: 6600, weekend: 8400 },
    //         { start: '2023-04-29', end: '2023-05-07', price: 7800, weekend: 9980 },
    //         { start: '2023-07-15', end: '2023-09-04', price: 7800, weekend: 9980 },
    //     ]
    // },

    // // 小諸高原
    // { cid: 110, prices: [
    //         { start: '2023-04-17', end: '2023-04-23', price: 6900, saturday: 13500, holiday: 12500 },
    //         { start: '2023-04-24', end: '2023-05-02', price: 7400, weekend: 13500 },
    //         { start: '2023-05-01', end: '2023-05-02', price: 13500 },
    //         { start: '2023-05-03', end: '2023-05-05', price: 15800 },
    //         { start: '2023-05-03', end: '2023-05-07', saturday: 15800, holiday: 13500 },
    //         { start: '2023-05-08', end: '2023-07-09', price: 7400, saturday: 13500, holiday: 12500 },
    //         { start: '2023-07-10', end: '2023-07-14', price: 6400 },
    //         { start: '2023-07-15', end: '2023-07-30', price: 9800, saturday: 16300, holiday: 15300 },
    //         { start: '2023-07-16', price: 16300 },
    //         { start: '2023-07-31', end: '2023-08-06', price: 10800, saturday: 16300, holiday: 15300 },
    //         { start: '2023-08-07', end: '2023-08-15', price: 11800, saturday: 19300, holiday: 19300 },
    //         { start: '2023-08-14', end: '2023-08-15', price: 19300 },
    //         { start: '2023-08-16', end: '2023-08-25', price: 11800, saturday: 16300, holiday: 15300 },
    //         { start: '2023-08-16', end: '2023-08-18', price: 16300 },
    //         { start: '2023-08-26', end: '2023-09-01', price: 9800, saturday: 16300, holiday: 15300 },
    //         { start: '2023-09-02', end: '2023-11-05', price: 8400, saturday: 14500, holiday: 13500 },
    //         { start: '2023-09-17', price: 14500 },
    //         { start: '2023-10-08', price: 14500 },
    //         { start: '2023-11-03', price: 14500 },
    //         { start: '2023-11-06', end: '2023-11-12', price: 7400, saturday: 13500, holiday: 12500 },
    //     ]
    // },
    // // 小諸高原 食事付き
    // { cid: 111, prices: [
    //         { start: '2023-04-01', end: '2023-04-23', price: 6900, saturday: 11500, holiday: 10500 },
    //         { start: '2023-04-08', end: '2023-04-09', saturday: 12500, holiday: 11500 },
    //         { start: '2023-04-15', end: '2023-04-16', saturday: 13500, holiday: 12500 },
    //         { start: '2023-11-13', end: '2023-11-19', price: 7400, saturday: 12500, holiday: 11500 },
    //         { start: '2023-11-20', end: '2023-11-30', price: 6900, saturday: 12500, holiday: 11500 },
    //     ]
    // },
    // // 三井の森 食事付き
    // { cid: 112, prices: [
    //         { start: '2023-04-04', end: '2023-04-16', price: 5700, weekend: 8800 },
    //         { start: '2023-04-17', end: '2023-04-23', price: 6500, weekend: 11800 },
    //         { start: '2023-04-24', end: '2023-04-28', price: 9800 },
    //         { start: '2023-04-29', end: '2023-05-06', price: 15800 },
    //         { start: '2023-05-07', end: '2023-06-30', price: 7100, weekend: 12500 },
    //         { start: '2023-07-01', end: '2023-07-14', price: 7800, weekend: 12800 },
    //         { start: '2023-07-15', end: '2023-07-31', price: 9500, weekend: 16500 },
    //         { start: '2023-07-17', price: 12800 },
    //         { start: '2023-08-01', end: '2023-08-04', price: 13500 },
    //         { start: '2023-08-05', end: '2023-08-20', price: 16800 },
    //         { start: '2023-08-21', end: '2023-08-31', price: 12800, weekend: 16800 },
    //         { start: '2023-09-01', end: '2023-09-30', price: 9800, weekend: 14800 },
    //         { start: '2023-10-01', end: '2023-11-02', price: 7800, weekend: 13800 },
    //         { start: '2023-10-09', price: 11800 },
    //         { start: '2023-11-03', price: 12800 },
    //         { start: '2023-11-04', price: 11800 },
    //         { start: '2023-11-05', end: '2023-11-30', price: 6000, saturday: 10800, holiday: 9800 },
    //         { start: '2023-11-23', price: 9500 },
    //         { start: '2023-11-25', price: 9500 },
    //         { start: '2023-11-26', price: 8500 },
    //     ]
    // },

    // 2022年度
    // 72 東 入山
    // { cid: 1, mg_start: '2022-04-11', mg_end: '2022-04-22', mg_weekday_price: 6400, mg_saturday_price: 9600, mg_holiday_price: 9100, mg_club72: true, },
    // { cid: 1, mg_start: '2022-11-01', mg_end: '2022-11-23', mg_weekday_price: 6400, mg_saturday_price: 9600, mg_holiday_price: 9100, mg_club72: true, },
    // { cid: 1, mg_start: '2022-04-23', mg_end: '2022-06-30', mg_weekday_price: 9100, mg_saturday_price: 15200, mg_holiday_price: 14700, mg_club72: true, },
    // { cid: 1, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 9100, mg_saturday_price: 15200, mg_holiday_price: 14700, mg_club72: true, },
    // { cid: 1, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 12400, mg_saturday_price: 19300, mg_holiday_price: 18800, mg_club72: true, },
    // { cid: 1, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 12400, mg_saturday_price: 19300, mg_holiday_price: 18800, mg_club72: true, },
    // { cid: 1, mg_start: '2022-07-16', mg_end: '2022-08-31', mg_weekday_price: 14300, mg_saturday_price: 20500, mg_holiday_price: 20000, mg_club72: true, },
    // { cid: 1, mg_start: '2022-04-11', mg_end: '2022-04-22', mg_weekday_price: 10100, mg_saturday_price: 17300, mg_holiday_price: 16800, },
    // { cid: 1, mg_start: '2022-11-01', mg_end: '2022-11-23', mg_weekday_price: 10100, mg_saturday_price: 17300, mg_holiday_price: 16800, },
    // { cid: 1, mg_start: '2022-04-23', mg_end: '2022-06-30', mg_weekday_price: 15700, mg_saturday_price: 23400, mg_holiday_price: 22900, },
    // { cid: 1, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 15700, mg_saturday_price: 23400, mg_holiday_price: 22900, },
    // { cid: 1, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 19900, mg_saturday_price: 27500, mg_holiday_price: 27000, },
    // { cid: 1, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 19900, mg_saturday_price: 27500, mg_holiday_price: 27000, },
    // { cid: 1, mg_start: '2022-07-16', mg_end: '2022-08-31', mg_weekday_price: 28000, mg_saturday_price: 28500, mg_holiday_price: 28000, },
    // // 72 東 押立
    // { cid: 2, mg_start: '2022-04-11', mg_end: '2022-04-22', mg_weekday_price: 5900, mg_saturday_price: 9100, mg_holiday_price: 8600, mg_club72: true, },
    // { cid: 2, mg_start: '2022-11-01', mg_end: '2022-11-23', mg_weekday_price: 5900, mg_saturday_price: 9100, mg_holiday_price: 8600, mg_club72: true, },
    // { cid: 2, mg_start: '2022-04-23', mg_end: '2022-06-30', mg_weekday_price: 8600, mg_saturday_price: 14700, mg_holiday_price: 14200, mg_club72: true, },
    // { cid: 2, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 8600, mg_saturday_price: 14700, mg_holiday_price: 14200, mg_club72: true, },
    // { cid: 2, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 11400, mg_saturday_price: 18300, mg_holiday_price: 17800, mg_club72: true, },
    // { cid: 2, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 11400, mg_saturday_price: 18300, mg_holiday_price: 17800, mg_club72: true, },
    // { cid: 2, mg_start: '2022-07-16', mg_end: '2022-08-31', mg_weekday_price: 13300, mg_saturday_price: 19500, mg_holiday_price: 19000, mg_club72: true, },
    // { cid: 2, mg_start: '2022-04-11', mg_end: '2022-04-22', mg_weekday_price: 9100, mg_saturday_price: 16300, mg_holiday_price: 15800, },
    // { cid: 2, mg_start: '2022-11-01', mg_end: '2022-11-23', mg_weekday_price: 9100, mg_saturday_price: 16300, mg_holiday_price: 15800, },
    // { cid: 2, mg_start: '2022-04-23', mg_end: '2022-06-30', mg_weekday_price: 14700, mg_saturday_price: 22400, mg_holiday_price: 21900, },
    // { cid: 2, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 14700, mg_saturday_price: 22400, mg_holiday_price: 21900, },
    // { cid: 2, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 18900, mg_saturday_price: 26500, mg_holiday_price: 26000, },
    // { cid: 2, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 18900, mg_saturday_price: 26500, mg_holiday_price: 26000, },
    // { cid: 2, mg_start: '2022-07-16', mg_end: '2022-08-31', mg_weekday_price: 26900, mg_saturday_price: 27400, mg_holiday_price: 26900, },
    // // 72 西
    // { cid: 3, mg_start: '2022-04-09', mg_end: '2022-04-22', mg_weekday_price: 4900, mg_saturday_price: 8600, mg_holiday_price: 8100, mg_club72: true, },
    // { cid: 3, mg_start: '2022-11-01', mg_end: '2022-11-30', mg_weekday_price: 4900, mg_saturday_price: 8600, mg_holiday_price: 8100, mg_club72: true, },
    // { cid: 3, mg_start: '2022-04-23', mg_end: '2022-06-30', mg_weekday_price: 6700, mg_saturday_price: 9600, mg_holiday_price: 9100, mg_club72: true, },
    // { cid: 3, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 6700, mg_saturday_price: 9600, mg_holiday_price: 9100, mg_club72: true, },
    // { cid: 3, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 10000, mg_saturday_price: 14300, mg_holiday_price: 13800, mg_club72: true, },
    // { cid: 3, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 10000, mg_saturday_price: 14300, mg_holiday_price: 13800, mg_club72: true, },
    // { cid: 3, mg_start: '2022-07-16', mg_end: '2022-08-07', mg_weekday_price: 10500, mg_saturday_price: 16300, mg_holiday_price: 15800, mg_club72: true, },
    // { cid: 3, mg_start: '2022-08-13', mg_end: '2022-08-31', mg_weekday_price: 10500, mg_saturday_price: 16300, mg_holiday_price: 15800, mg_club72: true, },
    // { cid: 3, mg_start: '2022-08-08', mg_end: '2022-08-12', mg_weekday_price: 11500, mg_saturday_price: 0, mg_holiday_price: 15800, mg_club72: true, },
    // { cid: 3, mg_start: '2022-04-09', mg_end: '2022-04-22', mg_weekday_price: 6600, mg_saturday_price: 12500, mg_holiday_price: 12000},
    // { cid: 3, mg_start: '2022-11-01', mg_end: '2022-11-30', mg_weekday_price: 6600, mg_saturday_price: 12500, mg_holiday_price: 12000},
    // { cid: 3, mg_start: '2022-04-23', mg_end: '2022-06-30', mg_weekday_price: 9900, mg_saturday_price: 16800, mg_holiday_price: 16300},
    // { cid: 3, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 9900, mg_saturday_price: 16800, mg_holiday_price: 16300},
    // { cid: 3, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 13800, mg_saturday_price: 20100, mg_holiday_price: 19600},
    // { cid: 3, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 13800, mg_saturday_price: 20100, mg_holiday_price: 18600},
    // { cid: 3, mg_start: '2022-07-16', mg_end: '2022-08-07', mg_weekday_price: 14500, mg_saturday_price: 20500, mg_holiday_price: 20100},
    // { cid: 3, mg_start: '2022-08-13', mg_end: '2022-08-31', mg_weekday_price: 14500, mg_saturday_price: 20500, mg_holiday_price: 20100},
    // { cid: 3, mg_start: '2022-08-08', mg_end: '2022-08-12', mg_weekday_price: 15500, mg_saturday_price: 0, mg_holiday_price: 20100},
    // // 72 南
    // { cid: 4, mg_start: '2022-04-01', mg_end: '2022-04-22', mg_weekday_price: 4900, mg_saturday_price: 8600, mg_holiday_price: 8100, mg_club72: true, },
    // { cid: 4, mg_start: '2022-11-01', mg_end: '2022-11-30', mg_weekday_price: 4900, mg_saturday_price: 8600, mg_holiday_price: 8100, mg_club72: true, },
    // { cid: 4, mg_start: '2022-04-23', mg_end: '2022-06-30', mg_weekday_price: 6700, mg_saturday_price: 9600, mg_holiday_price: 9100, mg_club72: true, },
    // { cid: 4, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 6700, mg_saturday_price: 9600, mg_holiday_price: 9100, mg_club72: true, },
    // { cid: 4, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 10000, mg_saturday_price: 14300, mg_holiday_price: 13800, mg_club72: true, },
    // { cid: 4, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 10000, mg_saturday_price: 14300, mg_holiday_price: 13800, mg_club72: true, },
    // { cid: 4, mg_start: '2022-07-16', mg_end: '2022-08-07', mg_weekday_price: 10500, mg_saturday_price: 16300, mg_holiday_price: 15800, mg_club72: true, },
    // { cid: 4, mg_start: '2022-08-13', mg_end: '2022-08-31', mg_weekday_price: 10500, mg_saturday_price: 16300, mg_holiday_price: 15800, mg_club72: true, },
    // { cid: 4, mg_start: '2022-08-08', mg_end: '2022-08-12', mg_weekday_price: 11500, mg_saturday_price: 0, mg_holiday_price: 15800, mg_club72: true, },
    // { cid: 4, mg_start: '2022-12-01', mg_end: '2022-12-04', mg_weekday_price: 3400, mg_saturday_price: 6400, mg_holiday_price: 5900, mg_club72: true, },
    // { cid: 4, mg_start: '2022-04-01', mg_end: '2022-04-22', mg_weekday_price: 6600, mg_saturday_price: 12500, mg_holiday_price: 12000, },
    // { cid: 4, mg_start: '2022-11-01', mg_end: '2022-11-30', mg_weekday_price: 6600, mg_saturday_price: 12500, mg_holiday_price: 12000, },
    // { cid: 4, mg_start: '2022-04-23', mg_end: '2022-06-30', mg_weekday_price: 9900, mg_saturday_price: 16800, mg_holiday_price: 16300, },
    // { cid: 4, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 9900, mg_saturday_price: 16800, mg_holiday_price: 16300, },
    // { cid: 4, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 13800, mg_saturday_price: 20100, mg_holiday_price: 19600, },
    // { cid: 4, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 13800, mg_saturday_price: 20100, mg_holiday_price: 19600, },
    // { cid: 4, mg_start: '2022-07-16', mg_end: '2022-08-07', mg_weekday_price: 14500, mg_saturday_price: 20500, mg_holiday_price: 20100, },
    // { cid: 4, mg_start: '2022-08-13', mg_end: '2022-08-31', mg_weekday_price: 14500, mg_saturday_price: 20500, mg_holiday_price: 20100, },
    // { cid: 4, mg_start: '2022-08-08', mg_end: '2022-08-12', mg_weekday_price: 15500, mg_saturday_price: 0, mg_holiday_price: 20100, },
    // { cid: 4, mg_start: '2022-12-01', mg_end: '2022-12-04', mg_weekday_price: 4900, mg_saturday_price: 8100, mg_holiday_price: 7900, },
    // // 72 北
    // { cid: 5, mg_start: '2022-06-01', mg_end: '2022-06-30', mg_weekday_price: 14100, mg_saturday_price: 20300, mg_holiday_price: 19800, mg_club72: true, },
    // { cid: 5, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 14100, mg_saturday_price: 20300, mg_holiday_price: 19800, mg_club72: true, },
    // { cid: 5, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 19500, mg_saturday_price: 24700, mg_holiday_price: 24200, mg_club72: true, },
    // { cid: 5, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 19500, mg_saturday_price: 24700, mg_holiday_price: 24200, mg_club72: true, },
    // { cid: 5, mg_start: '2022-07-16', mg_end: '2022-08-31', mg_weekday_price: 23800, mg_saturday_price: 26700, mg_holiday_price: 26200, mg_club72: true, },
    // { cid: 5, mg_start: '2022-11-01', mg_end: '2022-11-06', mg_weekday_price: 11800, mg_saturday_price: 14800, mg_holiday_price: 14300, mg_club72: true, },
    // { cid: 5, mg_start: '2022-06-01', mg_end: '2022-06-30', mg_weekday_price: 21300, mg_saturday_price: 28500, mg_holiday_price: 28000, },
    // { cid: 5, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 21300, mg_saturday_price: 28500, mg_holiday_price: 28000, },
    // { cid: 5, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 27500, mg_saturday_price: 34600, mg_holiday_price: 34100, },
    // { cid: 5, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 27500, mg_saturday_price: 34600, mg_holiday_price: 34100, },
    // { cid: 5, mg_start: '2022-07-16', mg_end: '2022-08-31', mg_weekday_price: 35700, mg_saturday_price: 36200, mg_holiday_price: 35700, },
    // { cid: 5, mg_start: '2022-11-01', mg_end: '2022-11-06', mg_weekday_price: 15700, mg_saturday_price: 22900, mg_holiday_price: 22400, },
    // // 晴山
    // { cid: 6, mg_start: '2022-04-09', mg_end: '2022-04-22', mg_weekday_price: 4065, mg_saturday_price: 6065, mg_holiday_price: 6065, mg_club72: true, },
    // { cid: 6, mg_start: '2022-11-01', mg_end: '2022-11-23', mg_weekday_price: 4065, mg_saturday_price: 6065, mg_holiday_price: 6065, mg_club72: true, },
    // { cid: 6, mg_start: '2022-04-23', mg_end: '2022-04-28', mg_weekday_price: 4865, mg_saturday_price: 7865, mg_holiday_price: 7865, mg_club72: true, },
    // { cid: 6, mg_start: '2022-05-06', mg_end: '2022-06-30', mg_weekday_price: 4865, mg_saturday_price: 7865, mg_holiday_price: 7865, mg_club72: true, },
    // { cid: 6, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 4865, mg_saturday_price: 7865, mg_holiday_price: 7865, mg_club72: true, },
    // { cid: 6, mg_start: '2022-04-29', mg_end: '2022-05-05', mg_weekday_price: 5865, mg_saturday_price: 9465, mg_holiday_price: 9465, mg_club72: true, },
    // { cid: 6, mg_start: '2022-07-15', mg_end: '2022-05-05', mg_weekday_price: 5865, mg_saturday_price: 9465, mg_holiday_price: 9465, mg_club72: true, },
    // { cid: 6, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 5865, mg_saturday_price: 9465, mg_holiday_price: 9465, mg_club72: true, },
    // { cid: 6, mg_start: '2022-07-16', mg_end: '2022-08-05', mg_weekday_price: 7365, mg_saturday_price: 10165, mg_holiday_price: 10165, mg_club72: true, },
    // { cid: 6, mg_start: '2022-08-15', mg_end: '2022-08-31', mg_weekday_price: 7365, mg_saturday_price: 10165, mg_holiday_price: 10165, mg_club72: true, },
    // { cid: 6, mg_start: '2022-08-06', mg_end: '2022-08-14', mg_weekday_price: 11165, mg_saturday_price: 11165, mg_holiday_price: 11165, mg_club72: true, },
    // { cid: 6, mg_start: '2022-04-09', mg_end: '2022-04-22', mg_weekday_price: 5965, mg_saturday_price: 10165, mg_holiday_price: 10165, },
    // { cid: 6, mg_start: '2022-11-01', mg_end: '2022-11-23', mg_weekday_price: 5965, mg_saturday_price: 10165, mg_holiday_price: 10165, },
    // { cid: 6, mg_start: '2022-04-23', mg_end: '2022-04-28', mg_weekday_price: 7065, mg_saturday_price: 11165, mg_holiday_price: 11165, },
    // { cid: 6, mg_start: '2022-05-06', mg_end: '2022-06-30', mg_weekday_price: 7065, mg_saturday_price: 11165, mg_holiday_price: 11165, },
    // { cid: 6, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 7065, mg_saturday_price: 11165, mg_holiday_price: 11165, },
    // { cid: 6, mg_start: '2022-04-29', mg_end: '2022-05-05', mg_weekday_price: 8065, mg_saturday_price: 13165, mg_holiday_price: 13165, },
    // { cid: 6, mg_start: '2022-07-15', mg_end: '2022-05-05', mg_weekday_price: 8065, mg_saturday_price: 13165, mg_holiday_price: 13165, },
    // { cid: 6, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 8065, mg_saturday_price: 13165, mg_holiday_price: 13165, },
    // { cid: 6, mg_start: '2022-07-16', mg_end: '2022-08-05', mg_weekday_price: 9665, mg_saturday_price: 15765, mg_holiday_price: 15765, },
    // { cid: 6, mg_start: '2022-08-15', mg_end: '2022-08-31', mg_weekday_price: 9665, mg_saturday_price: 15765, mg_holiday_price: 15765, },
    // { cid: 6, mg_start: '2022-08-06', mg_end: '2022-08-14', mg_weekday_price: 15765, mg_saturday_price: 15765, mg_holiday_price: 15765, },
    // // 浅間
    // { cid: 7, mg_start: '2022-04-09', mg_end: '2022-04-22', mg_weekday_price: 4100, mg_saturday_price: 8100, mg_holiday_price: 7600, mg_club72: true, },
    // { cid: 7, mg_start: '2022-11-01', mg_end: '2022-11-23', mg_weekday_price: 4100, mg_saturday_price: 8100, mg_holiday_price: 7600, mg_club72: true, },
    // { cid: 7, mg_start: '2022-04-23', mg_end: '2022-06-30', mg_weekday_price: 6100, mg_saturday_price: 9200, mg_holiday_price: 8700, mg_club72: true, },
    // { cid: 7, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 6100, mg_saturday_price: 9200, mg_holiday_price: 8700, mg_club72: true, },
    // { cid: 7, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 7300, mg_saturday_price: 11900, mg_holiday_price: 11400, mg_club72: true, },
    // { cid: 7, mg_start: '2022-09-12', mg_end: '2022-10-10', mg_weekday_price: 7300, mg_saturday_price: 11900, mg_holiday_price: 11400, mg_club72: true, },
    // { cid: 7, mg_start: '2022-07-16', mg_end: '2022-08-05', mg_weekday_price: 9700, mg_saturday_price: 14800, mg_holiday_price: 14300, mg_club72: true, },
    // { cid: 7, mg_start: '2022-08-15', mg_end: '2022-09-11', mg_weekday_price: 9700, mg_saturday_price: 14800, mg_holiday_price: 14300, mg_club72: true, },
    // { cid: 7, mg_start: '2022-08-06', mg_end: '2022-08-14', mg_weekday_price: 11700, mg_saturday_price: 14800, mg_holiday_price: 14800, mg_club72: true, },
    // { cid: 7, mg_start: '2022-04-09', mg_end: '2022-04-22', mg_weekday_price: 7200, mg_saturday_price: 12800, mg_holiday_price: 12300, },
    // { cid: 7, mg_start: '2022-11-01', mg_end: '2022-11-23', mg_weekday_price: 7200, mg_saturday_price: 12800, mg_holiday_price: 12300, },
    // { cid: 7, mg_start: '2022-04-23', mg_end: '2022-06-30', mg_weekday_price: 11600, mg_saturday_price: 17000, mg_holiday_price: 16500, },
    // { cid: 7, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 11600, mg_saturday_price: 17000, mg_holiday_price: 16500, },
    // { cid: 7, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 12700, mg_saturday_price: 18200, mg_holiday_price: 17700, },
    // { cid: 7, mg_start: '2022-09-12', mg_end: '2022-10-10', mg_weekday_price: 12700, mg_saturday_price: 18200, mg_holiday_price: 17700, },
    // { cid: 7, mg_start: '2022-07-16', mg_end: '2022-08-05', mg_weekday_price: 15800, mg_saturday_price: 20400, mg_holiday_price: 19900, },
    // { cid: 7, mg_start: '2022-08-15', mg_end: '2022-09-11', mg_weekday_price: 15800, mg_saturday_price: 20400, mg_holiday_price: 19900, },
    // { cid: 7, mg_start: '2022-08-06', mg_end: '2022-08-14', mg_weekday_price: 20900, mg_saturday_price: 20900, mg_holiday_price: 20900, },
    // // 馬越
    // { cid: 8, mg_start: '2022-04-01', mg_end: '2022-04-22', mg_weekday_price: 1800, mg_saturday_price: 3300, mg_holiday_price: 3300, mg_half: true, mg_club72: true, },
    // { cid: 8, mg_start: '2022-11-01', mg_end: '2022-11-27', mg_weekday_price: 1800, mg_saturday_price: 3300, mg_holiday_price: 3300, mg_half: true, mg_club72: true, },
    // { cid: 8, mg_start: '2022-04-23', mg_end: '2022-04-28', mg_weekday_price: 3300, mg_saturday_price: 4500, mg_holiday_price: 4500, mg_half: true, mg_club72: true, },
    // { cid: 8, mg_start: '2022-05-09', mg_end: '2022-06-30', mg_weekday_price: 3300, mg_saturday_price: 4500, mg_holiday_price: 4500, mg_half: true, mg_club72: true, },
    // { cid: 8, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 3300, mg_saturday_price: 4500, mg_holiday_price: 4500, mg_half: true, mg_club72: true, },
    // { cid: 8, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 3300, mg_saturday_price: 5300, mg_holiday_price: 5300, mg_half: true, mg_club72: true, },
    // { cid: 8, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 3300, mg_saturday_price: 5300, mg_holiday_price: 5300, mg_half: true, mg_club72: true, },
    // { cid: 8, mg_start: '2022-04-29', mg_end: '2022-05-08', mg_weekday_price: 4500, mg_saturday_price: 6500, mg_holiday_price: 6500, mg_half: true, mg_club72: true, },
    // { cid: 8, mg_start: '2022-07-16', mg_end: '2022-08-31', mg_weekday_price: 4500, mg_saturday_price: 6500, mg_holiday_price: 6500, mg_half: true, mg_club72: true, },
    // { cid: 8, mg_start: '2022-04-01', mg_end: '2022-04-22', mg_weekday_price: 3000, mg_saturday_price: 5500, mg_holiday_price: 5500, mg_half: true, },
    // { cid: 8, mg_start: '2022-11-01', mg_end: '2022-11-27', mg_weekday_price: 3000, mg_saturday_price: 5500, mg_holiday_price: 5500, mg_half: true, },
    // { cid: 8, mg_start: '2022-04-23', mg_end: '2022-04-28', mg_weekday_price: 4500, mg_saturday_price: 6000, mg_holiday_price: 6000, mg_half: true, },
    // { cid: 8, mg_start: '2022-05-09', mg_end: '2022-06-30', mg_weekday_price: 4500, mg_saturday_price: 6000, mg_holiday_price: 6000, mg_half: true, },
    // { cid: 8, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 4500, mg_saturday_price: 6000, mg_holiday_price: 6000, mg_half: true, },
    // { cid: 8, mg_start: '2022-07-01', mg_end: '2022-07-15', mg_weekday_price: 5500, mg_saturday_price: 7000, mg_holiday_price: 7000, mg_half: true, },
    // { cid: 8, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 5500, mg_saturday_price: 7000, mg_holiday_price: 7000, mg_half: true, },
    // { cid: 8, mg_start: '2022-04-29', mg_end: '2022-05-08', mg_weekday_price: 6700, mg_saturday_price: 8200, mg_holiday_price: 8200, mg_half: true, },
    // { cid: 8, mg_start: '2022-07-16', mg_end: '2022-08-31', mg_weekday_price: 6700, mg_saturday_price: 8200, mg_holiday_price: 8200, mg_half: true, },
    // // 晴山
    // { cid: 9, mg_start: '2022-04-09', mg_end: '2022-04-22', mg_weekday_price: 2765, mg_saturday_price: 4365, mg_holiday_price: 4365, mg_half: true, mg_club72: true, },
    // { cid: 9, mg_start: '2022-11-01', mg_end: '2022-11-23', mg_weekday_price: 2765, mg_saturday_price: 4365, mg_holiday_price: 4365, mg_half: true, mg_club72: true, },
    // { cid: 9, mg_start: '2022-04-23', mg_end: '2022-04-28', mg_weekday_price: 3565, mg_saturday_price: 5365, mg_holiday_price: 5365, mg_half: true, mg_club72: true, },
    // { cid: 9, mg_start: '2022-05-06', mg_end: '2022-06-30', mg_weekday_price: 3565, mg_saturday_price: 5365, mg_holiday_price: 5365, mg_half: true, mg_club72: true, },
    // { cid: 9, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 3565, mg_saturday_price: 5365, mg_holiday_price: 5365, mg_half: true, mg_club72: true, },
    // { cid: 9, mg_start: '2022-04-29', mg_end: '2022-05-05', mg_weekday_price: 4865, mg_saturday_price: 6865, mg_holiday_price: 6865, mg_half: true, mg_club72: true, },
    // { cid: 9, mg_start: '2022-07-15', mg_end: '2022-05-05', mg_weekday_price: 4865, mg_saturday_price: 6865, mg_holiday_price: 6865, mg_half: true, mg_club72: true, },
    // { cid: 9, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 4865, mg_saturday_price: 6865, mg_holiday_price: 6865, mg_half: true, mg_club72: true, },
    // { cid: 9, mg_start: '2022-07-16', mg_end: '2022-08-05', mg_weekday_price: 5865, mg_saturday_price: 7365, mg_holiday_price: 7365, mg_half: true, mg_club72: true, },
    // { cid: 9, mg_start: '2022-08-15', mg_end: '2022-08-31', mg_weekday_price: 5865, mg_saturday_price: 7365, mg_holiday_price: 7365, mg_half: true, mg_club72: true, },
    // { cid: 9, mg_start: '2022-08-06', mg_end: '2022-08-14', mg_weekday_price: 7365, mg_saturday_price: 7365, mg_holiday_price: 7365, mg_half: true, mg_club72: true, },
    // { cid: 9, mg_start: '2022-04-09', mg_end: '2022-04-22', mg_weekday_price: 4265, mg_saturday_price: 6265, mg_holiday_price: 6265, mg_half: true, },
    // { cid: 9, mg_start: '2022-11-01', mg_end: '2022-11-23', mg_weekday_price: 4265, mg_saturday_price: 6265, mg_holiday_price: 6265, mg_half: true, },
    // { cid: 9, mg_start: '2022-04-23', mg_end: '2022-04-28', mg_weekday_price: 5265, mg_saturday_price: 7265, mg_holiday_price: 7265, mg_half: true, },
    // { cid: 9, mg_start: '2022-05-06', mg_end: '2022-06-30', mg_weekday_price: 5265, mg_saturday_price: 7265, mg_holiday_price: 7265, mg_half: true, },
    // { cid: 9, mg_start: '2022-10-11', mg_end: '2022-10-31', mg_weekday_price: 5265, mg_saturday_price: 7265, mg_holiday_price: 7265, mg_half: true, },
    // { cid: 9, mg_start: '2022-04-29', mg_end: '2022-05-05', mg_weekday_price: 6265, mg_saturday_price: 8865, mg_holiday_price: 8865, mg_half: true, },
    // { cid: 9, mg_start: '2022-07-15', mg_end: '2022-05-05', mg_weekday_price: 6265, mg_saturday_price: 8865, mg_holiday_price: 8865, mg_half: true, },
    // { cid: 9, mg_start: '2022-09-01', mg_end: '2022-10-10', mg_weekday_price: 6265, mg_saturday_price: 8865, mg_holiday_price: 8865, mg_half: true, },
    // { cid: 9, mg_start: '2022-07-16', mg_end: '2022-08-05', mg_weekday_price: 7265, mg_saturday_price: 9365, mg_holiday_price: 9365, mg_half: true, },
    // { cid: 9, mg_start: '2022-08-15', mg_end: '2022-08-31', mg_weekday_price: 7265, mg_saturday_price: 9365, mg_holiday_price: 9365, mg_half: true, },
    // { cid: 9, mg_start: '2022-08-06', mg_end: '2022-08-14', mg_weekday_price: 9365, mg_saturday_price: 9365, mg_holiday_price: 9365, mg_half: true, },
    // // 小諸高原
    // { cid: 10, mg_start: '2022-04-18', mg_end: '2022-04-24', mg_weekday_price: 6700, mg_saturday_price: 13300, mg_holiday_price: 12300, },
    // { cid: 10, mg_start: '2022-04-25', mg_end: '2022-04-30', mg_weekday_price: 7200, mg_saturday_price: 15300, mg_holiday_price: 13300, },
    // { cid: 10, mg_start: '2022-05-01', mg_end: '2022-05-06', mg_weekday_price: 15300, mg_saturday_price: 15300, mg_holiday_price: 13300, },
    // { cid: 10, mg_start: '2022-05-07', mg_end: '2022-07-10', mg_weekday_price: 7200, mg_saturday_price: 13300, mg_holiday_price: 12300, },
    // { cid: 10, mg_start: '2022-07-11', mg_end: '2022-07-18', mg_weekday_price: 8200, mg_saturday_price: 15300, mg_holiday_price: 12300, },
    // { cid: 10, mg_start: '2022-07-19', mg_end: '2022-07-31', mg_weekday_price: 9200, mg_saturday_price: 15300, mg_holiday_price: 14300, },
    // { cid: 10, mg_start: '2022-08-01', mg_end: '2022-08-07', mg_weekday_price: 10200, mg_saturday_price: 15300, mg_holiday_price: 14300, },
    // { cid: 10, mg_start: '2022-08-08', mg_end: '2022-08-15', mg_weekday_price: 10200, mg_saturday_price: 18300, mg_holiday_price: 15300, },
    // { cid: 10, mg_start: '2022-08-16', mg_end: '2022-08-21', mg_weekday_price: 10200, mg_saturday_price: 15300, mg_holiday_price: 14300, },
    // { cid: 10, mg_start: '2022-08-22', mg_end: '2022-08-26', mg_weekday_price: 9200, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 10, mg_start: '2022-08-27', mg_end: '2022-11-04', mg_weekday_price: 8200, mg_saturday_price: 14300, mg_holiday_price: 13300, },
    // { cid: 10, mg_start: '2022-11-05', mg_end: '2022-11-13', mg_weekday_price: 7200, mg_saturday_price: 13300, mg_holiday_price: 12300, },
    // // 小諸高原 食事付き
    // { cid: 11, mg_start: '2022-04-02', mg_end: '2022-04-08', mg_weekday_price: 6700, mg_saturday_price: 11300, mg_holiday_price: 10300, },
    // { cid: 11, mg_start: '2022-04-09', mg_end: '2022-04-15', mg_weekday_price: 6700, mg_saturday_price: 12300, mg_holiday_price: 11300, },
    // { cid: 11, mg_start: '2022-04-16', mg_end: '2022-04-17', mg_weekday_price: 0, mg_saturday_price: 13300, mg_holiday_price: 12300, },
    // { cid: 11, mg_start: '2022-11-14', mg_end: '2022-11-20', mg_weekday_price: 7200, mg_saturday_price: 12300, mg_holiday_price: 11300, },
    // { cid: 11, mg_start: '2022-11-21', mg_end: '2022-11-30', mg_weekday_price: 6700, mg_saturday_price: 12300, mg_holiday_price: 11300, },
    // // 三井の森 食事付き
    // { cid: 12, mg_start: '2022-09-01', mg_end: '2022-09-18', mg_weekday_price: 8650, mg_saturday_price: 11800, mg_holiday_price: 11800, mg_mitsuinotomo: true },
    // { cid: 12, mg_start: '2022-09-19', mg_end: '2022-09-19', mg_weekday_price: 11500, mg_saturday_price: 9500, mg_holiday_price: 9500, mg_mitsuinotomo: true },
    // { cid: 12, mg_start: '2022-09-20', mg_end: '2022-09-24', mg_weekday_price: 8650, mg_saturday_price: 11800, mg_holiday_price: 11800, mg_mitsuinotomo: true },
    // { cid: 12, mg_start: '2022-09-25', mg_end: '2022-09-25', mg_weekday_price: 11500, mg_saturday_price: 9500, mg_holiday_price: 9500, mg_mitsuinotomo: true },
    // { cid: 12, mg_start: '2022-09-26', mg_end: '2022-10-02', mg_weekday_price: 7600, mg_saturday_price: 10800, mg_holiday_price: 9800, mg_mitsuinotomo: true },
    // { cid: 12, mg_start: '2022-10-03', mg_end: '2022-10-16', mg_weekday_price: 6800, mg_saturday_price: 10800, mg_holiday_price: 9800, mg_mitsuinotomo: true },
    // { cid: 12, mg_start: '2022-10-10', mg_end: '2022-10-10', mg_weekday_price: 0, mg_saturday_price: 0, mg_holiday_price: 7800, mg_mitsuinotomo: true },
    // { cid: 12, mg_start: '2022-10-17', mg_end: '2022-11-03', mg_weekday_price: 6500, mg_saturday_price: 10800, mg_holiday_price: 9800, mg_mitsuinotomo: true },
    // { cid: 12, mg_start: '2022-11-04', mg_end: '2022-11-25', mg_weekday_price: 4800, mg_saturday_price: 7800, mg_holiday_price: 6800, mg_mitsuinotomo: true },
    // { cid: 12, mg_start: '2022-11-23', mg_end: '2022-11-23', mg_weekday_price: 0, mg_saturday_price: 0, mg_holiday_price: 6200, mg_mitsuinotomo: true },
    // { cid: 12, mg_start: '2022-11-26', mg_end: '2022-11-27', mg_weekday_price: 0, mg_saturday_price: 6200, mg_holiday_price: 5200, mg_mitsuinotomo: true },
    // { cid: 12, mg_start: '2022-09-01', mg_end: '2022-09-18', mg_weekday_price: 9650, mg_saturday_price: 14800, mg_holiday_price: 14800, },
    // { cid: 12, mg_start: '2022-09-19', mg_end: '2022-09-19', mg_weekday_price: 12500, mg_saturday_price: 12500, mg_holiday_price: 12500, },
    // { cid: 12, mg_start: '2022-09-20', mg_end: '2022-09-24', mg_weekday_price: 9650, mg_saturday_price: 14800, mg_holiday_price: 14800, },
    // { cid: 12, mg_start: '2022-09-25', mg_end: '2022-09-25', mg_weekday_price: 12500, mg_saturday_price: 12500, mg_holiday_price: 12500, },
    // { cid: 12, mg_start: '2022-09-26', mg_end: '2022-10-02', mg_weekday_price: 8600, mg_saturday_price: 13800, mg_holiday_price: 12800, },
    // { cid: 12, mg_start: '2022-10-03', mg_end: '2022-10-16', mg_weekday_price: 7800, mg_saturday_price: 13800, mg_holiday_price: 12800, },
    // { cid: 12, mg_start: '2022-10-10', mg_end: '2022-10-10', mg_weekday_price: 0, mg_saturday_price: 0, mg_holiday_price: 10800, },
    // { cid: 12, mg_start: '2022-10-17', mg_end: '2022-11-03', mg_weekday_price: 7500, mg_saturday_price: 13800, mg_holiday_price: 12800, },
    // { cid: 12, mg_start: '2022-11-04', mg_end: '2022-11-25', mg_weekday_price: 5800, mg_saturday_price: 10800, mg_holiday_price: 9800, },
    // { cid: 12, mg_start: '2022-11-23', mg_end: '2022-11-23', mg_weekday_price: 0, mg_saturday_price: 0, mg_holiday_price: 9200, },
    // { cid: 12, mg_start: '2022-11-26', mg_end: '2022-11-27', mg_weekday_price: 0, mg_saturday_price: 9200, mg_holiday_price: 8200, },
    //
    // // 高梨子倶楽部
    // { cid: 13, mg_start: '2022-09-09', mg_end: '2022-09-11', mg_weekday_price: 5470, mg_saturday_price: 11300, mg_holiday_price: 10750, },
    // { cid: 13, mg_start: '2022-09-12', mg_end: '2022-09-12', mg_weekday_price: 5800, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 13, mg_start: '2022-09-13', mg_end: '2022-09-15', mg_weekday_price: 5580, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 13, mg_start: '2022-09-16', mg_end: '2022-09-18', mg_weekday_price: 5800, mg_saturday_price: 11300, mg_holiday_price: 11850, },
    // { cid: 13, mg_start: '2022-09-19', mg_end: '2022-09-19', mg_weekday_price: 0, mg_saturday_price: 0, mg_holiday_price: 10200, },
    // { cid: 13, mg_start: '2022-09-20', mg_end: '2022-09-24', mg_weekday_price: 5910, mg_saturday_price: 12400, mg_holiday_price: 11850, },
    // { cid: 13, mg_start: '2022-09-25', mg_end: '2022-09-25', mg_weekday_price: 0, mg_saturday_price: 0, mg_holiday_price: 11300, },
    // { cid: 13, mg_start: '2022-09-27', mg_end: '2022-09-30', mg_weekday_price: 6130, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 13, mg_start: '2022-12-31', mg_end: '2022-12-31', mg_weekday_price: 0, mg_saturday_price: 10000, mg_holiday_price: 0, },
    // // 高梨子倶楽部 食事付き
    // { cid: 14, mg_start: '2022-09-09', mg_end: '2022-09-11', mg_weekday_price: 6240, mg_saturday_price: 12180, mg_holiday_price: 11630, },
    // { cid: 14, mg_start: '2022-09-12', mg_end: '2022-09-18', mg_weekday_price: 6570, mg_saturday_price: 11080, mg_holiday_price: 11630, },
    // { cid: 14, mg_start: '2022-09-19', mg_end: '2022-09-19', mg_weekday_price: 0, mg_saturday_price: 0, mg_holiday_price: 10180, },
    // { cid: 14, mg_start: '2022-09-20', mg_end: '2022-09-22', mg_weekday_price: 6790, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 14, mg_start: '2022-09-23', mg_end: '2022-09-23', mg_weekday_price: 0, mg_saturday_price: 0, mg_holiday_price: 11630, },
    // { cid: 14, mg_start: '2022-09-24', mg_end: '2022-09-30', mg_weekday_price: 6800, mg_saturday_price: 14280, mg_holiday_price: 12180, },
    // { cid: 14, mg_start: '2022-10-01', mg_end: '2022-10-09', mg_weekday_price: 7120, mg_saturday_price: 13280, mg_holiday_price: 13830, },
    // { cid: 14, mg_start: '2022-10-10', mg_end: '2022-10-10', mg_weekday_price: 0, mg_saturday_price: 0, mg_holiday_price: 12180, },
    // { cid: 14, mg_start: '2022-10-11', mg_end: '2022-10-16', mg_weekday_price: 7340, mg_saturday_price: 14380, mg_holiday_price: 13830, },
    // { cid: 14, mg_start: '2022-10-17', mg_end: '2022-10-23', mg_weekday_price: 7560, mg_saturday_price: 14930, mg_holiday_price: 14380, },
    // { cid: 14, mg_start: '2022-10-24', mg_end: '2022-11-03', mg_weekday_price: 7780, mg_saturday_price: 0, mg_holiday_price: 14380, },
    // { cid: 14, mg_start: '2022-11-04', mg_end: '2022-11-06', mg_weekday_price: 8880, mg_saturday_price: 14930, mg_holiday_price: 14380, },
    // { cid: 14, mg_start: '2022-11-07', mg_end: '2022-11-13', mg_weekday_price: 8220, mg_saturday_price: 16030, mg_holiday_price: 14930, },
    // { cid: 14, mg_start: '2022-11-14', mg_end: '2022-11-27', mg_weekday_price: 8660, mg_saturday_price: 16580, mg_holiday_price: 14930, },
    // { cid: 14, mg_start: '2022-11-28', mg_end: '2022-12-04', mg_weekday_price: 8440, mg_saturday_price: 16030, mg_holiday_price: 14930, },
    // { cid: 14, mg_start: '2022-12-05', mg_end: '2022-12-16', mg_weekday_price: 8220, mg_saturday_price: 16030, mg_holiday_price: 14930, },
    // { cid: 14, mg_start: '2022-12-17', mg_end: '2022-12-23', mg_weekday_price: 6900, mg_saturday_price: 14930, mg_holiday_price: 13830, },
    // { cid: 14, mg_start: '2022-12-25', mg_end: '2022-12-27', mg_weekday_price: 6900, mg_saturday_price: 0, mg_holiday_price: 11630, },
    // { cid: 14, mg_start: '2022-12-28', mg_end: '2022-12-28', mg_weekday_price: 8000, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 14, mg_start: '2022-12-29', mg_end: '2022-12-29', mg_weekday_price: 13830, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 14, mg_start: '2022-12-30', mg_end: '2022-12-30', mg_weekday_price: 13280, mg_saturday_price: 0, mg_holiday_price: 0, },
    // // 妙技
    // { cid: 15, mg_start: '2022-09-12', mg_end: '2022-09-29', mg_weekday_price: 3991, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 15, mg_start: '2022-10-03', mg_end: '2022-10-04', mg_weekday_price: 4446, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 15, mg_start: '2022-10-05', mg_end: '2022-10-05', mg_weekday_price: 4718, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 15, mg_start: '2022-10-11', mg_end: '2022-10-28', mg_weekday_price: 5173, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 15, mg_start: '2022-10-31', mg_end: '2022-11-02', mg_weekday_price: 4446, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 15, mg_start: '2022-11-04', mg_end: '2022-11-04', mg_weekday_price: 5355, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 15, mg_start: '2022-11-07', mg_end: '2022-11-25', mg_weekday_price: 3900, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 15, mg_start: '2022-11-28', mg_end: '2022-11-30', mg_weekday_price: 4446, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 15, mg_start: '2022-12-01', mg_end: '2022-12-02', mg_weekday_price: 4900, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 15, mg_start: '2022-12-06', mg_end: '2022-12-09', mg_weekday_price: 4446, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 15, mg_start: '2022-12-12', mg_end: '2022-12-16', mg_weekday_price: 3991, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 15, mg_start: '2022-12-19', mg_end: '2022-12-27', mg_weekday_price: 3537, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 15, mg_start: '2022-12-28', mg_end: '2022-12-28', mg_weekday_price: 3991, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 15, mg_start: '2022-12-29', mg_end: '2022-12-31', mg_weekday_price: 10810, mg_saturday_price: 8537, mg_holiday_price: 0, },
    // // 妙技 食事付き
    // { cid: 16, mg_start: '2022-09-12', mg_end: '2022-10-04', mg_weekday_price: 4900, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 16, mg_start: '2022-10-05', mg_end: '2022-10-07', mg_weekday_price: 5173, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 16, mg_start: '2022-10-11', mg_end: '2022-10-28', mg_weekday_price: 4628, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 16, mg_start: '2022-10-31', mg_end: '2022-11-02', mg_weekday_price: 5355, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 16, mg_start: '2022-11-07', mg_end: '2022-11-25', mg_weekday_price: 5809, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 16, mg_start: '2022-11-28', mg_end: '2022-11-30', mg_weekday_price: 5355, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 16, mg_start: '2022-12-01', mg_end: '2022-12-02', mg_weekday_price: 5810, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 16, mg_start: '2022-12-05', mg_end: '2022-12-08', mg_weekday_price: 5355, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 16, mg_start: '2022-12-12', mg_end: '2022-12-16', mg_weekday_price: 4900, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 16, mg_start: '2022-12-19', mg_end: '2022-12-27', mg_weekday_price: 4446, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 16, mg_start: '2022-12-28', mg_end: '2022-12-28', mg_weekday_price: 4900, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 16, mg_start: '2022-12-29', mg_end: '2022-12-30', mg_weekday_price: 11719, mg_saturday_price: 0, mg_holiday_price: 0, },
    // { cid: 16, mg_start: '2022-12-03', mg_end: '2022-12-11', mg_weekday_price: 0, mg_saturday_price: 12628, mg_holiday_price: 12628, },
    // { cid: 16, mg_start: '2022-12-17', mg_end: '2022-12-18', mg_weekday_price: 0, mg_saturday_price: 11719, mg_holiday_price: 11719, },
    // { cid: 16, mg_start: '2022-12-24', mg_end: '2022-12-31', mg_weekday_price: 0, mg_saturday_price: 9446, mg_holiday_price: 9446, },
    // // 太平洋クラブ 食事付き
    // { cid: 17, mg_start: '2022-09-12', mg_end: '2022-09-29', mg_weekday_price: 5455, mg_saturday_price: 11000, mg_holiday_price: 11000, },
    // { cid: 17, mg_start: '2022-10-01', mg_end: '2022-11-29', mg_weekday_price: 6000, mg_saturday_price: 11000, mg_holiday_price: 11000, },

]
export default prices;
