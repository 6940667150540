const courses = [
    // ゴルフコースのマスタ
    { id: 1, mg_name: '軽井沢72ゴルフ 東 入山', mg_short_name: '72東 入山', mg_kitasaku: true, },
    { id: 2, mg_name: '軽井沢72ゴルフ 東 押立', mg_short_name: '72東 押立', mg_kitasaku: true, },
    { id: 10, mg_name: '軽井沢72ゴルフ 東コース', mg_short_name: '72東', mg_kitasaku: true, },
    { id: 3, mg_name: '軽井沢72ゴルフ 西コース', mg_short_name: '72西', mg_kitasaku: true, },
    { id: 4, mg_name: '軽井沢72ゴルフ 南コース', mg_short_name: '72南', mg_kitasaku: true, },
    { id: 5, mg_name: '軽井沢72ゴルフ 北コース', mg_short_name: '72北', mg_kitasaku: true, },
    { id: 11, mg_name: '軽井沢72ゴルフ 北コース セルフ', mg_short_name: '72北 セルフ', mg_kitasaku: true, },
    { id: 6, mg_name: '晴山ゴルフ場', mg_short_name: '晴山', mg_kitasaku: true, },
    { id: 7, mg_name: '浅間ゴルフコース', mg_short_name: '浅間', mg_kitasaku: true, },
    { id: 8, mg_name: '馬越ゴルフコース(9ホール)', mg_short_name: '馬越9', mg_kitasaku: true, },
    { id: 9, mg_name: '晴山ゴルフ場(9ホール)', mg_short_name: '晴山9', mg_kitasaku: true, },

    { id: 110, mg_name: '小諸高原ゴルフクラブ', mg_short_name: '小諸高原', mg_kitasaku: true, },
    { id: 111, mg_name: '小諸高原ゴルフクラブ(食事)', mg_short_name: '小諸高原(食)', mg_kitasaku: true, mg_lunch: true, },
    { id: 112, mg_name: '三井の森軽井沢カントリークラブ(食事)', mg_short_name: '三井の森(食)', mg_kitasaku: true, mg_lunch: true},

    { id: 113, mg_name: '高梨子倶楽部', mg_short_name: '高梨子', mg_gunma: true, },
    { id: 114, mg_name: '高梨子倶楽部(食事)', mg_short_name: '高梨子(食)', mg_gunma: true, mg_lunch: true},
    { id: 115, mg_name: '妙義カントリークラブ', mg_short_name: '妙義', mg_gunma: true, },
    { id: 116, mg_name: '妙義カントリークラブ(食事)', mg_short_name: '妙義(食)', mg_gunma: true, mg_lunch: true},
    { id: 117, mg_name: '太平洋クラブ高崎コース(食事)', mg_short_name: '太平洋(食)', mg_gunma: true, mg_lunch: true},
    { id: 118, mg_name: '中軽井沢バーディークラブ', mg_short_name: '中軽', mg_kitasaku: true, },
    { id: 119, mg_name: '中軽井沢バーディークラブ(食事)', mg_short_name: '中軽(食)', mg_kitasaku: true, mg_lunch: true, },
    { id: 120, mg_name: 'ヴィレッジ東軽井沢ゴルフクラブ(食事)', mg_short_name: 'ヴィレッジ(食)', mg_gunma: true, mg_lunch: true, },

]
export default courses;
