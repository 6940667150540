<template>
  <div class="container-lg">
    <div class="accordion" id="accordion">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne" style="padding: 3px; padding-left: 10px;"
                  v-on:click="saveCollapse">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear"
                 viewBox="0 0 16 16">
              <path
                  d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
              <path
                  d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
            </svg>
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
             v-bind:class="{ show: !collapse_flg }"
             data-bs-parent="#accordion">
          <div class="accordion-body">

            <div class="row">
              <div class="col-4">
                <label for="colFormLabelLg" class="col-form-label col-form-label-sm">価格上限</label>
                <select class="form-select form-control form-control-sm" id="max_price" v-model="max_price"
                        @change="conditionChange">
                  <option value="0"></option>
                  <option value="30000">〜3万円</option>
                  <option value="20000">〜2万円</option>
                  <option value="10000">〜1万円</option>
                </select>
              </div>
              <div class="col-3">
                <label for="colFormLabelLg" class="col-form-label col-form-label-sm">表示数</label>
                <select class="form-select form-control form-control-sm" id="max_show" v-model="max_show"
                        @change="conditionChange">
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="all">全部</option>
                </select>
              </div>

            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="true" id="half_flag" v-model="half_flag"
                         @change="conditionChange">
                  <label class="form-check-label" for="half_flag">
                    ハーフ
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="true" id="weekend"
                         v-model="calendarOptions.weekends"
                         @change="conditionChange">
                  <label class="form-check-label" for="weekend">
                    週末
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="true" id="lunch_true"
                         v-model="lunch_true"
                         @change="conditionChange">
                  <label class="form-check-label" for="lunch_true">
                    食事付き
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="true" id="lunch_false"
                         v-model="lunch_false"
                         @change="conditionChange">
                  <label class="form-check-label" for="lunch_false">
                    食事なし
                  </label>
                </div>
              </div>
<!--              <div class="col">-->
<!--                <div class="form-check">-->
<!--                  <input class="form-check-input" type="checkbox" value="true" id="kitasaku" v-model="kitasaku_flag"-->
<!--                         @change="conditionChange">-->
<!--                  <label class="form-check-label" for="kitasaku">-->
<!--                    北佐久郡-->
<!--                  </label>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col">-->
<!--                <div class="form-check">-->
<!--                  <input class="form-check-input" type="checkbox" value="true" id="gunma" v-model="gunma_flag"-->
<!--                         @change="conditionChange">-->
<!--                  <label class="form-check-label" for="gunma">-->
<!--                    群馬-->
<!--                  </label>-->
<!--                </div>-->
<!--              </div>-->
            </div>
            <div class="row mt-3">

              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="true" id="club72flag" v-model="club72flag"
                         @change="conditionChange">
                  <label class="form-check-label text-truncate" for="club72flag">
                    CLUB72会員価格
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="true" id="club72flag" v-model="vpass_flag"
                         @change="conditionChange">
                  <label class="form-check-label text-truncate" for="club72flag">
                    V PASS会員価格
                  </label>
                </div>
              </div>
<!--              <div class="col">-->
<!--                <div class="form-check">-->
<!--                  <input class="form-check-input" type="checkbox" value="true" id="mitsui_tomo_flag"-->
<!--                         v-model="mitsui_tomo_flag"-->
<!--                         @change="conditionChange">-->
<!--                  <label class="form-check-label text-truncate" for="mitsui_tomo_flag">-->
<!--                    三井の森友の会価格-->
<!--                  </label>-->
<!--                </div>-->
<!--              </div>-->
            </div>


          </div>
        </div>
      </div>
    </div>

    <br>
    <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
  </div>
  <div class="container-lg">
    <div>
      <p style="font-size: x-small" class="text-secondary">※データは手入力していますので正確とは限りません。お申し込みの際はゴルフ場のWeb等でご確認ください<br>
        ※不具合や間違いにお気づきの際は karu.golf@gmail.com 宛にお知らせいただけると助かります</p>
    </div>
  </div>

  <div class="position-absolute top-0 start-50 translate-middle">
    <h6 style="position:relative;top: 29px;" class="text-primary text-opacity-25 fw-bold">
      軽ゴル
    </h6>
  </div>

</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import jaLocale from '@fullcalendar/core/locales/ja';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import * as holiday_jp from '@holiday-jp/holiday_jp';
import randomColor from "randomcolor";
import {Tooltip} from "bootstrap";
import {useCookies} from "vue3-cookies";

import courses from './courses.js'; // コースのマスタ
import prices from './prices.js';   // 価格のデータ
import event72 from './event72.js';   // ダブルスタンプデーとか

export default {
  name: 'App',
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, bootstrap5Plugin],
        initialView: 'dayGridMonth',
        height: 'auto',
        dayCellContent: function (e) { // 日付に'日'をとる
          e.dayNumberText = e.dayNumberText.replace('日', '');
        },
        headerToolbar: {start: 'prev today next', center: 'title', end: ''},
        weekends: true,
        locale: jaLocale,
        firstDay: 1,
        themeSystem: 'bootstrap5',
        eventOrder: 'price',
        eventOrderStrict: true,
        eventMouseEnter: mouse_enter,
        eventMouseLeave: mouse_leave,
        events: [],
        eventContent: event_content
      },
      prices: prices,
      max_price: '0',
      max_show: '3',
      half_flag: true,
      kitasaku_flag: true,
      gunma_flag: true,
      club72flag: false,
      vpass_flag: false,
      lunch_true: true,
      lunch_false: true,
      mitsui_tomo_flag: false,
      collapse_flg: false
    }
  },
  created: function () { // 初期化処理
    load_cookies(this)
    load_events(this)
    this.$gtag.config('G-R0CPYHQVW8', {'page_path': '/'});
  },
  setup: function () {
    const {cookies} = useCookies();
    return {cookies};
  },
  mounted: function () {
  },
  methods: {
    conditionChange: function () {
      save_cookies(this)
      load_events(this);
      this.$gtag.event("condition_change")
    },
    saveCollapse: function () {
      this.collapse_flg = !this.collapse_flg;
      save_cookies(this)
      this.$gtag.event("collapse")
    }
  },
  components: {
    FullCalendar
  }
}

let tooltipInstance = null;

// eventの中身の表示
function event_content(arg) {
  let double_stamp_mark = ''
  let ladys_day_mark = ''
  let short_name = ''
  const event = arg.event
  if (event.extendedProps.holiday_event) // 休日表示はパス
    return ''

  if (event.extendedProps.double_stamp_mark)
    double_stamp_mark = '<img src="/double_stamp_day.png">' // ダブルスタンプデー
  if (event.extendedProps.ladys_day_mark)
    ladys_day_mark = '<img src="/ladys_day_mark.png">' // レディースデー
  short_name = '<div class="d-none d-sm-block">' + event.extendedProps.short_name + '</div>' // 短縮名
  return {
    html: `
<div className="fc-event-title-container" style="display: flex;">
  <div style="margin-right: auto;" className="fc-event-title fc-sticky">${event.title}</div>
      ${double_stamp_mark}${ladys_day_mark}${short_name}
</div>
`
  }
}


function mouse_enter(info) {
  if (info.event.extendedProps.description) {
    tooltipInstance = new Tooltip(info.el, {
      title: info.event.extendedProps.description,
      html: true,
      placement: "left",
      trigger: "hover",
      container: "body"
    });

    tooltipInstance.show();
  }

}

function mouse_leave() {
  if (tooltipInstance) {
    tooltipInstance.dispose();
    tooltipInstance = null;
  }
}

// クッキーに保存する
function save_cookies(t) {
  t.cookies.set('max_price', t.max_price);
  t.cookies.set('max_show', t.max_show);
  t.cookies.set('club72flag', t.club72flag);
  t.cookies.set('vpass_flag', t.vpass_flag);
  t.cookies.set('mitsui_tomo_flag', t.mitsui_tomo_flag);
  t.cookies.set('half_flag', t.half_flag);
  t.cookies.set('kitasaku_flag', t.kitasaku_flag);
  t.cookies.set('gunma_flag', t.gunma_flag);
  t.cookies.set('lunch_true', t.lunch_true);
  t.cookies.set('lunch_false', t.lunch_false);
  t.cookies.set('weekends', t.calendarOptions.weekends);
  t.cookies.set('collapse_flg', t.collapse_flg);
}

// クッキーから設定をロードする
function load_cookies(t) {
  t.max_price = t.cookies.get('max_price') || '0'
  t.max_show = t.cookies.get('max_show') || '3'
  t.club72flag = toBoolean(t.cookies.get('club72flag') || 'false')
  t.vpass_flag = toBoolean(t.cookies.get('vpass_flag') || 'false')
  t.mitsui_tomo_flag = toBoolean(t.cookies.get('mitsui_tomo_flag') || 'false')
  t.half_flag = toBoolean(t.cookies.get('half_flag') || 'true')
  t.kitasaku_flag = toBoolean(t.cookies.get('kitasaku_flag') || 'true')
  t.gunma_flag = toBoolean(t.cookies.get('gunma_flag') || 'true')
  t.lunch_true = toBoolean(t.cookies.get('lunch_true') || 'true')
  t.lunch_false = toBoolean(t.cookies.get('lunch_false') || 'true')
  t.calendarOptions.weekends = toBoolean(t.cookies.get('weekends') || 'true')
  t.collapse_flg = toBoolean(t.cookies.get('collapse_flg') || 'false')
}

function toBoolean(booleanStr) {
  return booleanStr.toLowerCase() === "true";
}

// 表示するイベントを選択してカレンダーを更新
function load_events(t) {
  // console.log('conditionChange');
  // console.log(t.club72flag);

  const events = [] // 一時的おき場所
  let ppd = {}
  // 該当する値段を算出
  t.prices.forEach(function (data) { // 一行ずつ読み込んで
    let cid = data.cid
    let club72 = data.club72
    let vpass = data.vpass
    let mg_half = data.mg_half
    let course = get_course(cid)
    // console.log(course);
    data.prices.forEach(function (price) {
      const s_date = new Date(price.start)
      const e_date = price.end === undefined ? new Date(price.start) : new Date(price.end) // endが無いケースに対応

      for (let day = s_date; day <= e_date; day.setDate(day.getDate() + 1)) {
        let event = {
          day: day, date: date2str(day), backgroundColor: randomColor({luminosity: 'bright', seed: cid * 23}),
          extendedProps: {description: course.mg_name}, cid: cid
        }

        if (!t.lunch_true && course.mg_lunch)
          continue;
        if (!t.lunch_false && course.mg_lunch === undefined)
          continue;
        // if (!t.kitasaku_flag && course.mg_kitasaku)
        //   continue;
        // if (!t.gunma_flag && course.mg_gunma)
        //   continue;
        if ((cid == 120) && ((t.vpass_flag == false && vpass) || (t.vpass_flag == true && vpass === undefined))) // vpassの選択とマッチしてない価格情報は無視
          continue;
        if ((cid <= 99) && ((t.club72flag == false && club72) || (t.club72flag == true && club72 === undefined)))
          continue;
        // if ((cid == 112) && ((t.mitsui_tomo_flag == false && price.mg_mitsuinotomo) || (t.mitsui_tomo_flag == true && price.mg_mitsuinotomo === undefined)))
        //   continue;
        let p;
        if (holiday_jp.isHoliday(day) || day.getDay() == 0) { // 休日か日曜日
          p = price.holiday || price.weekend || price.price || 0
        } else if (day.getDay() == 6) { // 土曜日
          p = price.saturday || price.weekend || price.price || 0
        } else { // 平日
          p = price.price || 0
        }
        if (p == 0)
          continue
        if (t.max_price != 0 && p > t.max_price) // 上限価格より高いのは無視
          continue
        if ((t.half_flag == false) && (mg_half !== undefined)) // ハーフフラグによっては
          continue
        if (t.club72flag && club72) {
          let ev = get72event(day, cid)
          if (ev.dsd)
            event['extendedProps']['double_stamp_mark'] = true;
          if (ev.ldd)
            event['extendedProps']['ladys_day_mark'] = true;
        }
        event['price'] = p;
        event['extendedProps']['short_name'] = course.mg_short_name;

        if (typeof ppd[day] === "undefined") // その日のエントリーがなければ初期化
          ppd[day] = []

        // 同じゴルフ場の同じ日のデータが来た場合の上書き処理
        let update_flg = false
        ppd[day].map((v, index) => {
          if (v.cid == cid) {
            ppd[day][index] = event
            update_flg = true
          }
        })
        if (!update_flg) { // 上書きじゃないので普通に追加
          ppd[day].push(event);
        }
      }
    })
  })

  // 安い順3つ(default)だけ表示
  Object.keys(ppd).forEach(function (key) {
    let day_ary = ppd[key]
    let ary = day_ary.sort((n1, n2) => {
      if (parseInt(n1.price) > parseInt(n2.price)) {
        return 1
      }
      if (parseInt(n1.price) < parseInt(n2.price)) {
        return -1
      }
      return 0
    })
    if (t.max_show != 'all')
      ary = ary.slice(0, t.max_show)
    for (let index = 0; index < ary.length; index++) {
      let day = ary[index]
      day['title'] = day['price'].toLocaleString()
      events.push(day)
    }
  })

  // 祝日に色をつける処理
  const current_year = new Date().getFullYear()
  const holidays = holiday_jp.between(new Date(current_year - 1, 1, 1), new Date(current_year + 1, 12, 31));
  for (let index = 0; index < holidays.length; index++) {
    let event = {
      date: date2str(holidays[index].date), display: 'background', backgroundColor: 'red',
      extendedProps: {holiday_event: true}
    }
    events.push(event)
  }

  t.calendarOptions.events = events;
}

function date2str(date) {
  const y = date.getFullYear()
  const m = (date.getMonth() + 1).toString().padStart(2, "0")
  const d = date.getDate().toString().padStart(2, "0")
  return `${y}-${m}-${d}`
}

function get_course(id) {
  let res
  courses.forEach(function (course) {
    if (course.id == id)
      res = course
  })
  return res
}

function get72event(date, cid) {
  let res = false
  event72.forEach(function (ev) {
    if ((ev.cid === cid || ev.cid === 'a') && date2str(date) === ev.date)
      res = ev
  })
  return res
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 10px;
}

/* まれにスマホでカレンダーが横に縮んでしまうバグに対処 */
.fc-col-header, .fc-scrollgrid-sync-table, .fc-daygrid-body-natural {
  width: 100% !important;
}
</style>
