import { createApp } from 'vue'
import App from './App.vue'

import "bootstrap/dist/css/bootstrap.min.css"
import VueGtag from "vue-gtag";

createApp(App).use(VueGtag, {
    config: {
        id: 'G-R0CPYHQVW8',
        params: {
            send_page_view: false
        }
    }
}).mount('#app')
