const event72 = [
    { date: '2023-04-01', cid: 10, dsd: true},
    { date: '2023-04-02', cid: 10, dsd: true},
    { date: '2023-04-03', cid: 10, dsd: true},
    { date: '2023-04-04', cid: 10, dsd: true},
    { date: '2023-04-08', cid: 6, dsd: true},
    { date: '2023-04-09', cid: 6, dsd: true},
    { date: '2023-04-10', cid: 4, dsd: true},
    { date: '2023-04-11', cid: 4, dsd: true},
    { date: '2023-04-12', cid: 7, dsd: true},
    { date: '2023-04-14', cid: 8, ldd: true},
    { date: '2023-04-15', cid: 3, dsd: true},
    { date: '2023-04-16', cid: 3, dsd: true},
    { date: '2023-04-17', cid: 3, dsd: true},
    { date: '2023-04-18', cid: 8, dsd: true},
    { date: '2023-04-19', cid: 7, dsd: true},
    { date: '2023-04-20', cid: 10, ldd: true},
    { date: '2023-04-20', cid: 3, ldd: true},
    { date: '2023-04-20', cid: 4, ldd: true},
    { date: '2023-04-22', cid: 6, ldd: true},
    { date: '2023-04-24', cid: 10, dsd: true},
    { date: '2023-04-26', cid: 7, dsd: true},
    { date: '2023-04-27', cid: 3, dsd: true},
    { date: '2023-04-28', cid: 8, dsd: true},
    { date: '2023-04-30', cid: 4, dsd: true},

    { date: '2023-05-01', cid: 3, dsd: true},
    { date: '2023-05-05', cid: 8, dsd: true},
    { date: '2023-05-07', cid: 'a', dsd: true},
    { date: '2023-05-08', cid: 8, dsd: true},
    { date: '2023-05-09', cid: 6, dsd: true},
    { date: '2023-05-10', cid: 7, dsd: true},
    { date: '2023-05-11', cid: 4, dsd: true},
    { date: '2023-05-15', cid: 10, dsd: true},
    { date: '2023-05-15', cid: 3, ldd: true},
    { date: '2023-05-15', cid: 4, ldd: true},
    { date: '2023-05-15', cid: 4, ldd: true},
    { date: '2023-05-16', cid: 10, dsd: true},
    { date: '2023-05-17', cid: 7, dsd: true},
    { date: '2023-05-19', cid: 8, dsd: true},
    { date: '2023-05-20', cid: 3, dsd: true},
    { date: '2023-05-21', cid: 5, dsd: true},
    { date: '2023-05-23', cid: 10, dsd: true},
    { date: '2023-05-24', cid: 7, dsd: true},
    { date: '2023-05-25', cid: 4, dsd: true},
    { date: '2023-05-26', cid: 6, ldd: true},
    { date: '2023-05-27', cid: 8, dsd: true},
    { date: '2023-05-28', cid: 8, ldd: true},
    { date: '2023-05-30', cid: 6, dsd: true},
    { date: '2023-05-31', cid: 7, ldd: true},

    // { date: '2022-08-01', cid: 1, dsd: true},
    // { date: '2022-08-01', cid: 2, dsd: true},
    // { date: '2022-08-02', cid: 6, dsd: true},
    // { date: '2022-08-04', cid: 4, dsd: true},
    // { date: '2022-08-06', cid: 8, ldd: true},
    // { date: '2022-08-08', cid: 8, dsd: true},
    // { date: '2022-08-09', cid: 3, dsd: true},
    // { date: '2022-08-10', cid: 'a', ldd: true},
    // { date: '2022-08-14', cid: 7, dsd: true},
    // { date: '2022-08-16', cid: 8, dsd: true},
    // { date: '2022-08-18', cid: 1, dsd: true},
    // { date: '2022-08-18', cid: 2, dsd: true},
    // { date: '2022-08-21', cid: 7, ldd: true},
    // { date: '2022-08-22', cid: 1, dsd: true},
    // { date: '2022-08-22', cid: 2, dsd: true},
    // { date: '2022-08-22', cid: 3, dsd: true},
    // { date: '2022-08-23', cid: 5, dsd: true},
    // { date: '2022-08-24', cid: 7, dsd: true},
    // { date: '2022-08-25', cid: 6, dsd: true},
    // { date: '2022-08-26', cid: 4, dsd: true},
    // { date: '2022-08-28', cid: 8, dsd: true},
    // { date: '2022-08-29', cid: 6, ldd: true},
    // { date: '2022-08-30', cid: 3, dsd: true},
    // { date: '2022-08-31', cid: 7, dsd: true},
    //
    // { date: '2022-09-01', cid: 3, dsd: true},
    // { date: '2022-09-02', cid: 6, dsd: true},
    // { date: '2022-09-03', cid: 8, dsd: true},
    // { date: '2022-09-04', cid: 1, dsd: true},
    // { date: '2022-09-04', cid: 2, dsd: true},
    // { date: '2022-09-05', cid: 6, dsd: true},
    // { date: '2022-09-06', cid: 3, dsd: true},
    // { date: '2022-09-08', cid: 4, dsd: true},
    // { date: '2022-09-10', cid: 8, ldd: true},
    // { date: '2022-09-12', cid: 1, dsd: true},
    // { date: '2022-09-12', cid: 2, dsd: true},
    // { date: '2022-09-14', cid: 7, dsd: true},
    // { date: '2022-09-15', cid: 'a', ldd: true},
    // { date: '2022-09-16', cid: 8, dsd: true},
    // { date: '2022-09-19', cid: 7, ldd: true},
    // { date: '2022-09-20', cid: 3, dsd: true},
    // { date: '2022-09-21', cid: 6, dsd: true},
    // { date: '2022-09-22', cid: 4, dsd: true},
    // { date: '2022-09-24', cid: 8, dsd: true},
    // { date: '2022-09-25', cid: 6, dsd: true},
    // { date: '2022-09-26', cid: 1, dsd: true},
    // { date: '2022-09-26', cid: 2, dsd: true},
    // { date: '2022-09-26', cid: 3, dsd: true},
    // { date: '2022-09-27', cid: 5, dsd: true},
    // { date: '2022-09-29', cid: 9, ldd: true},
    //
    // { date: '2022-10-01', cid: 8, dsd: true},
    // { date: '2022-10-02', cid: 3, dsd: true},
    // { date: '2022-10-03', cid: 4, dsd: true},
    // { date: '2022-10-04', cid: 6, dsd: true},
    // { date: '2022-10-05', cid: 7, dsd: true},
    // { date: '2022-10-07', cid: 8, ldd: true},
    // { date: '2022-10-10', cid: 1, dsd: true},
    // { date: '2022-10-10', cid: 2, dsd: true},
    // { date: '2022-10-11', cid: 6, ldd: true},
    // { date: '2022-10-12', cid: 7, dsd: true},
    // { date: '2022-10-13', cid: 4, dsd: true},
    // { date: '2022-10-15', cid: 8, dsd: true},
    // { date: '2022-10-16', cid: 8, dsd: true},
    // { date: '2022-10-18', cid: 1, dsd: true},
    // { date: '2022-10-18', cid: 2, dsd: true},
    // { date: '2022-10-18', cid: 3, dsd: true},
    // { date: '2022-10-19', cid: 5, dsd: true},
    // { date: '2022-10-20', cid: 6, dsd: true},
    // { date: '2022-10-21', cid: 7, dsd: true},
    // { date: '2022-10-21', cid: 7, ldd: true},
    // { date: '2022-10-24', cid: 'a', ldd: true},
    // { date: '2022-10-25', cid: 4, dsd: true},
    // { date: '2022-10-26', cid: 7, dsd: true},
    // { date: '2022-10-27', cid: 1, dsd: true},
    // { date: '2022-10-27', cid: 2, dsd: true},
    // { date: '2022-10-28', cid: 8, dsd: true},
    // { date: '2022-10-30', cid: 3, dsd: true},


]
export default event72;
